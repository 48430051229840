import { createAction, props } from '@ngrx/store';
import { VulcanKeys } from '@prc-lib-core';
import { AnswersPayload } from '@prc-serenity-store/app-state.model';
import { AnswsersWorksState } from '../reducers/answers-works.reducer';

export enum ActionTypes {
  Init = '[Answers Works] Init',
  Update = '[Answers Works] Update',
  Confirmation = '[Answers Works] Confirmation',
  UpdateOne = '[Answers Works] Update One',
}

export interface SelectedAnswer {
  question?: VulcanKeys;
  answer?: string;
}

export const AnswersWorksInitAction = createAction(ActionTypes.Init);

export const AnswersWorksUpdateAction = createAction(ActionTypes.Update, props<{ payload: AnswsersWorksState }>());

export const AnswersWorksUpdateSomeAction = createAction(ActionTypes.UpdateOne, props<{ payload: AnswersPayload }>());
